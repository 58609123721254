<template>
  <div>
    <nav :class="{ blurred: isActive }" class="navbar is-fixed-top is-primary">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img src="@/assets/logo.png" alt="Tutor Hire Logo" class="logo" />
        </a>
        <a
          role="button"
          class="navbar-burger"
          :class="{ 'is-active': isActive }"
          @click="toggleNavbar"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div class="navbar-menu navbar-end" :class="{ 'is-active': isActive }">
        <a class="navbar-item" href="/" @click="closeNavbar">
          <i class="fas fa-home"></i> Home
        </a>
        <a class="navbar-item" href="/about" @click="closeNavbar">
          <i class="fas fa-info-circle"></i> About
        </a>
        <a class="navbar-item" href="/contact" @click="closeNavbar">
          <i class="fas fa-envelope"></i> Contact Us
        </a>
        <a class="navbar-item" href="/testimonials" @click="closeNavbar">
          <i class="fas fa-comment-alt"></i> Testimonials
        </a>
      </div>
    </nav>

    <!-- Sidebar and overlay -->
    <div class="overlay" v-if="isActive" @click="closeNavbar"></div>
    <div class="side-navbar" :class="{ 'is-active': isActive }">
      <a class="navbar-item" href="/" @click="closeNavbar">
        <i class="fas fa-home"></i> Home
      </a>
      <a class="navbar-item" href="/about" @click="closeNavbar">
        <i class="fas fa-info-circle"></i> About
      </a>
      <a class="navbar-item" href="/contact" @click="closeNavbar">
        <i class="fas fa-envelope"></i> Contact Us
      </a>
      <a class="navbar-item" href="/testimonials" @click="closeNavbar">
        <i class="fas fa-comment-alt"></i> Testimonials
      </a>
    </div>

    <!-- Main content -->
    <main :class="{ blurred: isActive }">
      <slot></slot>
    </main>
  </div>
</template>

<script>
export default {
  name: "NavbarPage",
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    toggleNavbar() {
      this.isActive = !this.isActive;
    },
    closeNavbar() {
      this.isActive = false;
    },
  },
};
</script>

<style scoped>
/* General Navbar Styling */
.logo {
  max-width: 150px;
}
.navbar-brand {
  display: flex;
  align-items: center;
}
.navbar-item {
  font-size: 1.2em;
  display: flex;
  align-items: center;
  padding: 15px;
  color: white;
  transition: background-color 0.3s ease;
}
.navbar-item:hover {
  background-color: #34495e;
}
.navbar-burger {
  display: none;
  cursor: pointer;
}
.navbar-burger span {
  background-color: #fff;
  height: 3px;
  margin: 3px 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.navbar-burger.is-active span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}
.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}
.navbar-burger.is-active span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Sidebar Styling */
.side-navbar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: #2c3e50;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: left 0.3s ease;
  z-index: 1030;
  padding-top: 60px;
}
.side-navbar.is-active {
  left: 0;
}

/* Overlay for sidebar */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1020;
}

/* Blur effect */
.blurred {
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
  transition: filter 0.3s ease;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-menu {
    display: none;
  }
  .navbar-menu.is-active {
    display: flex;
  }
  .navbar-burger {
    display: block;
  }
}
</style>
