<template>
  <div class="section">
    <div class="container">
    <br>
    <br>
      <h1 class="title has-text-centered">Testimonials</h1>
      <p class="content has-text-centered">
        Here’s what our satisfied students say about us:
      </p>
      <div class="columns is-multiline">
        <div class="column is-6" v-for="(testimonial, index) in testimonials" :key="index">
          <div class="box has-text-centered testimonial-box">
            <p class="is-size-5 mb-3">
              <i class="fas fa-quote-left"></i>
              {{ testimonial.text }}
              <i class="fas fa-quote-right"></i>
            </p>
            <p class="is-italic">- {{ testimonial.name }}</p>
            <div class="mt-3">
              <span v-for="n in Math.floor(testimonial.rating)" :key="n" class="icon has-text-warning">
                <i class="fas fa-star"></i>
              </span>
              <span v-if="testimonial.rating % 1 !== 0" class="icon has-text-warning">
                <i class="fas fa-star-half-alt"></i>
              </span>
              <span v-for="n in (10 - Math.ceil(testimonial.rating))" :key="n" class="icon has-text-grey-light">
                <i class="fas fa-star"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestimonialsPage',
  data() {
    return {
      testimonials: [
        { text: "Amazing service!", name: "Torrus", rating: 10 },
        { text: "Helped me get an A on my assignment.", name: "Elius Shamoon", rating: 10 },
        { text: "Fantastic experience, highly recommended!", name: "Alice Johnson", rating: 10 },
        { text: "Excellent tutors, very helpful.", name: "Michael Brown", rating: 10 },
        { text: "Great support throughout my course.", name: "Emily White", rating: 10 },
        { text: "Good service but a bit pricey.", name: "Chris Green", rating: 9.6 },
        { text: "Overall, a positive experience.", name: "Sophia Black", rating: 9.2 },
        { text: "Very satisfied with the results.", name: "Liam Red", rating: 8.8 },
        { text: "Would use again!", name: "Olivia Blue", rating: 10 },
        { text: "High quality work, just what I needed.", name: "William Violet", rating: 9.8 },
      ],
    };
  },
};
</script>

<style scoped>
.testimonial-box {
  animation: fadeInUp 0.5s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.icon.has-text-warning {
  font-size: 1.5em;
}
</style>
