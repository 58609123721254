<template>
  <div class="section">
    <div class="container">
      <div class="columns is-multiline is-vcentered">
        <!-- Title and Main Content -->
        <br>
        <div class="column is-12-tablet is-8-desktop">
          <h1 class="title has-text-primary has-text-centered-mobile">About Us</h1>
          <p class="content has-text-justified">
            <strong>Welcome to King Writers, the ultimate destination for professional academic writing assistance.</strong>
            <br /><br />
            At King Writers, we specialize in helping students and professionals improve their writing skills and achieve academic success. Our services are designed to provide tailored support for clients seeking expert guidance and high-quality writing solutions.
            <br /><br />
            Whether you're crafting an essay, fine-tuning a thesis, or looking for professional writing support, King Writers is here to help you succeed. Join thousands of satisfied clients who rely on King Writers for reliable, professional, and effective writing services.
          </p>
          
          <h2 class="subtitle has-text-centered">What We Offer:</h2>
          <ul>
            <li><strong>Personalized Essay Writing Suggestions:</strong> Receive customized feedback to enhance your essays and achieve top grades.</li>
            <li><strong>Peer Proofreading Services:</strong> Collaborate with peers to refine your work and gain valuable insights.</li>
            <li><strong>On-Campus Writing Advisory:</strong> Complement your institution’s writing resources with our expert advice.</li>
          </ul>
        </div>

        <!-- Icons Section -->
        <div class="column is-12-tablet is-4-desktop has-text-centered">
        <br>
          <h2 class="subtitle has-text-centered">Why Choose King Writers?</h2>
          <div class="columns is-multiline">
            <div class="column is-6-mobile is-12-tablet">
              <div class="icon-box">
                <span class="icon is-large has-text-primary">
                  <i class="fas fa-book-open fa-3x"></i>
                </span>
                <p class="mt-2">Expertise in academic writing across various fields.</p>
              </div>
            </div>
            <div class="column is-6-mobile is-12-tablet">
              <div class="icon-box">
                <span class="icon is-large has-text-primary">
                  <i class="fas fa-clock fa-3x"></i>
                </span>
                <p class="mt-2">Quick turnaround times without compromising quality.</p>
              </div>
            </div>
            <div class="column is-6-mobile is-12-tablet">
              <div class="icon-box">
                <span class="icon is-large has-text-primary">
                  <i class="fas fa-dollar-sign fa-3x"></i>
                </span>
                <p class="mt-2">Affordable pricing tailored to meet your needs.</p>
              </div>
            </div>
            <div class="column is-6-mobile is-12-tablet">
              <div class="icon-box">
                <span class="icon is-large has-text-primary">
                  <i class="fas fa-graduation-cap fa-3x"></i>
                </span>
                <p class="mt-2">Academic Success</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>

<style scoped>
.icon-box {
  text-align: center;
  margin: 20px 0;
}

.icon-box .icon {
  transition: transform 0.3s ease;
}

.icon-box:hover .icon {
  transform: scale(1.2);
}

.content {
  font-size: 1.1rem;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

ul li {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .title {
    font-size: 1.5rem;
  }

  .content {
    font-size: 1rem;
  }

  ul li {
    font-size: 1rem;
  }
}
</style>
