<template>
  <div class="section">
    <div class="container">
    <br>
    <br>
      <h1 class="title">Contact Us</h1>
      <p class="content">
        You can reach out to us via WhatsApp, Telegram, or Email for any inquiries or assistance.
      </p>
      <ContactOptions />
    </div>
  </div>
</template>

<script>
import ContactOptions from "@/components/ContactOptions.vue";

export default {
name: 'ContactPage',
  components: {
    ContactOptions,
  },
};
</script>
