<template>
  <div>
  <br>
  <br>
    <HeroSection @showContactOptions="contactOptionsVisible = true" />
    <ContactOptions v-if="contactOptionsVisible" />
  </div>
</template>

<script>
import HeroSection from "@/components/Herosection.vue";
import ContactOptions from "@/components/ContactOptions.vue";

export default {
name: 'HomePage',
  components: {
    HeroSection,
    ContactOptions,
  },
  data() {
    return {
      contactOptionsVisible: false,
    };
  },
};
</script>
